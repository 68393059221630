export enum TableCellTypes {
  DATE_TIME = 'datetime',
  ENUM = 'enum',
  MULTI = 'multi',
  BOOLEAN = 'boolean',
  STRING = 'string',
  LINK = 'link',
  NUMBER = 'number',
  RELATION = 'rel',
  IMAGE = 'image',
  DUMMY_EMAIL = 'email'
}
