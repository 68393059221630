import { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import {
  EuiButton,
  EuiFieldPassword,
  EuiFieldText,
  EuiForm,
  EuiFormControlLayout,
  EuiFormRow,
  EuiGlobalToastList,
  EuiPageHeader,
} from '@elastic/eui'

import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import { RoutePaths } from '../../shared/constants/enums/RoutePathsEnum'
import { useAuth } from '../../store/auth/use-auth'
import styles from './Login.module.scss'

const Login: React.FC = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isLoginBtnDisabled, setIsLoginBtnDisabled] = useState<boolean>(true)
  const [isInvalid, setIsInvalid] = useState<boolean>(false)

  useEffect(() => {
    setIsInvalid(!!auth.errors.length)
  }, [auth.errors])

  useEffect(() => {
    if (!auth.user) return
    navigate(RoutePaths.HOME)
  }, [auth.user])

  useEffect(() => {
    const isCredentialEmpty: boolean = !username.length || !password.length
    setIsLoginBtnDisabled(isCredentialEmpty)
  }, [username, password])

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value)
    setIsInvalid(false)
  }

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    setIsInvalid(false)
  }

  const onEnterClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter' || isLoginBtnDisabled) return
    handleLoginBtnClick(e)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLoginBtnClick = (e: any): void => {
    e.preventDefault()
    auth.signIn(username, password)
  }

  if (localStorage.getItem('auth')) {
    return <Navigate to={RoutePaths.HOME} replace />
  }

  return (
    <>
      <LoadingSpinner isVisible={auth.isLoading} />

      <EuiGlobalToastList
        toasts={auth.toasts}
        dismissToast={() => auth.setToasts([])}
        toastLifeTimeMs={3000}
      />

      <section className={`container--center ${styles.container}`}>
        <EuiPageHeader
          className={styles.header}
          pageTitle={t('login.title')}
          paddingSize='l'
          style={{ textAlign: 'center' }}
        />

        <EuiForm component='form' className='form'>
          <EuiFormRow label={t('login.emailLabel')}>
            <EuiFormControlLayout isLoading={auth.isLoading} isInvalid={isInvalid}>
              <EuiFieldText
                value={username}
                isInvalid={isInvalid}
                autoComplete='on'
                placeholder={t('login.emailPlaceholder')}
                onChange={onUsernameChange}
                onKeyDown={onEnterClick}
              />
            </EuiFormControlLayout>
          </EuiFormRow>

          <EuiFormRow label={t('login.passwordLabel')} className={styles.passwordFieldWrapper}>
            <EuiFormControlLayout isLoading={auth.isLoading} isInvalid={isInvalid}>
              <EuiFieldPassword
                className={styles.passwordField}
                value={password}
                isInvalid={isInvalid}
                autoComplete='on'
                placeholder={t('login.passwordPlaceholder')}
                type='password'
                onChange={onPasswordChange}
                onKeyDown={onEnterClick}
              />
            </EuiFormControlLayout>
          </EuiFormRow>

          <EuiButton
            type='submit'
            isDisabled={isLoginBtnDisabled || auth.isLoading}
            onClick={handleLoginBtnClick}
            iconType='check'
            fullWidth
            fill
          >
            {t('btns.login')}
          </EuiButton>
        </EuiForm>
      </section>
    </>
  )
}

export default Login
