import { Toast } from '@elastic/eui/src/components/toast/global_toast_list'

const patientsOverviewToasts: { [key: string]: Toast } = {
  loadPatientsOverviewSuccess: {
    id: 'success-patients-overview-load',
    title: 'Успешно зареждане!',
    color: 'success',
    iconType: 'check',
    text: 'Списъкът с пациент бе зареден успешно!',
  },
  loadPatientsOverviewFailed: {
    id: 'fail-patients-overview-load',
    title: 'Нещо се обърка.',
    color: 'danger',
    iconType: 'alert',
    text: 'Извинете ни, ще опитаме това да не се повтавя.',
  },
}

export default patientsOverviewToasts
