import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  title: string
  showFooter?: boolean;
  focusFieldName?: string
  children: React.ReactNode
  onClose: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (e: any) => void
}

const Modal = (props: ModalProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <EuiModal onClose={props.onClose} style={{ maxWidth: '400px' }}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>{props.title}</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>{props.children}</EuiModalBody>

      {props.showFooter ?
      <EuiModalFooter>
        <EuiButtonEmpty onClick={props.onClose}>{t('btns.cancel')}</EuiButtonEmpty>
        <EuiButton form='update-modal' fill onClick={props.onSubmit}>
          {t('btns.save')}
        </EuiButton>
      </EuiModalFooter> : <></>
      }
    </EuiModal>
  )
}

export default Modal
