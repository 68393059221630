import { Toast } from '@elastic/eui/src/components/toast/global_toast_list'

const patientDetailsToasts: { [key: string]: Toast } = {
  loadPatientDetailsSuccess: {
    id: 'success-patient-details-load',
    title: 'Loading complete!',
    color: 'success',
    iconType: 'check',
    text: 'The patient data was successfully loaded!',
  },
  loadPatientDetailsFailed: {
    id: 'fail-patient-details-load',
    title: 'Oops, there was an error.',
    color: 'danger',
    iconType: 'alert',
    text: 'Sorry. We will try to not let it happen it again.',
  },
  updatePatientSuccess: {
    id: 'success-patient-update',
    title: 'Done!',
    color: 'success',
    iconType: 'check',
    text: 'The patient was successfully updated!',
  },
  updatePatientFailed: {
    id: 'fail-patient-update',
    title: 'Oops, there was an error.',
    color: 'danger',
    iconType: 'alert',
    text: 'Sorry. We will try to not let it happen it again.',
  },
}

export default patientDetailsToasts
