import { EuiLoadingSpinner } from '@elastic/eui'
import styles from './LoadingSpinner.module.scss'

interface ILoadingSpinnerProps {
  isVisible: boolean
}

const LoadingSpinner: React.FC<ILoadingSpinnerProps> = (
  props: ILoadingSpinnerProps,
): JSX.Element => {
  return (
    <>
      {props.isVisible && (
        <div className={styles.container}>
          {<EuiLoadingSpinner className={styles.spinner} size='xl' />}
        </div>
      )}
    </>
  )
}

export default LoadingSpinner
