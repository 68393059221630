export enum Diagnosis {
  DEPRESSION = 'depression',
  ANXIETY = 'anxiety',
  DEPRESSIVE_ANXIETY = 'anxietyDepressive',
  INSOMNIA = 'insomnia',
}

export enum DiagnosisLabels {
  DEPRESSION = 'Депресия',
  ANXIETY = 'Тревожност',
  DEPRESSIVE_ANXIETY = 'Тревожно-депресивно',
  INSOMNIA = 'Инсомния',
  // DEPRESSION = 'Depression',
  // ANXIETY = 'Anxiety',
  // DEPRESSIVE_ANXIETY = 'Anxiety-depressive',
  // INSOMNIA = 'Insomnia',
}
