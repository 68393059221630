/* eslint-disable @typescript-eslint/no-explicit-any */
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list'
import { createContext, useContext, useState } from 'react'
import { API_URL } from '../../http'
import http from '../../http/httpsService'
// import { addMinutesToDate } from '../../../utils/timeManagement'

export const AuthContext = createContext<any>({})
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const AuthProvider = ({ children }: { children: any }) => {
  const auth = useProvideAuth()

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext)
}

const useProvideAuth = () => {
  const [user, setUser] = useState<{ username: string; userId: number } | null>(null)
  const [isAuth, setIsAuth] = useState<boolean | undefined>(undefined)
  const [errors, setErrors] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [toasts, setToasts] = useState<Toast[]>([])

  function signIn(username: string, password: string) {
    setUser(null)
    setErrors([])
    setIsLoading(true)

    const loginCredentials: {
      identifier: string
      password: string
    } = {
      identifier: username,
      password: password,
    }

    const headers = {
      'Content-Type': 'application/json',
    }

    http
      .post(`${API_URL}/api/v1/auth/local`, loginCredentials, { headers: headers })
      .then((response) => {
        localStorage.setItem('auth', response.data.jwt)
        localStorage.setItem('user', JSON.stringify(response.data.user))
        // TODO: adjust after adding <AuthVerify />
        // const authStartDate: Date = new Date()
        // const authExpireDate: Date = addMinutesToDate(15, authStartDate)
        // localStorage.setItem('authStartDate', authStartDate.getTime().toString())
        // localStorage.setItem('authExpireDate', authExpireDate.getTime().toString())

        setUser(response.data.user)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('error:', error)
        setErrors(['Unauthorized. Please try to log in again.'])
        setIsLoading(false)
      })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function signOut(toast?: Toast) {
    localStorage.removeItem('auth')
    localStorage.removeItem('user')
    // TODO: adjust after adding <AuthVerify />
    // localStorage.removeItem('authStartDate')
    // localStorage.removeItem('authExpireDate')
    setUser(null)
    setToasts(toast ? [toast] : [])
  }

  return {
    user,
    isAuth,
    errors,
    isLoading,
    toasts,
    signIn,
    signOut,
    setToasts,
    setIsAuth,
  }
}
