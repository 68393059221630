import { isIOS } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import styles from './App.module.scss'
import Navbar from './components/Navbar/Navbar'
import ROUTES, { RenderRoutes } from './components/Routes/Routes'
import { useAuth } from './store/auth/use-auth'

function App() {
  const auth = useAuth()

  return (
    <>
      {isIOS ? (
        <Helmet>
          <meta name='viewport' content='width=device-width, initial-scale=1 maximum-scale=1' />
        </Helmet>
      ) : (
        <></>
      )}
      <Navbar />
      <main className={styles.container}>
        <RenderRoutes isAuth={auth.isAuth} routes={ROUTES} />
      </main>
    </>
  )
}

export default App
