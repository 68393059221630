/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormControlLayout,
  EuiIcon,
  EuiSwitch,
  EuiSwitchEvent
} from '@elastic/eui'
import { Action } from '@elastic/eui/src/components/basic_table/action_types'
import queryString from 'query-string'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import Modal from '../../../components/Modal/Modal'
import { RoutePaths } from '../../../shared/constants/enums/RoutePathsEnum'
import {
  OverviewFields,
  OverviewFilters,
  OverviewHeaders
} from '../../../shared/constants/enums/overviewDataGridColumnsEnum'
import { Patient } from '../../../shared/models/Patient.model'
import { useData } from '../../../store/data/dataProvider'
import styles from './Overview.module.scss'
import {
  overviewDataGridDefaultColumns,
  overviewDataGridHiddenColumns,
  overviewTableColumns,
  renderCell,
} from './utils/OverviewGridConfig'
import { filterPatientsData } from './utils/OverviewUtils'

interface OverviewProps {}

const Overview: React.FC<OverviewProps> = () => {
  const dataProvider = useData()!
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()

  const [expandedRowIds, setExpandedRowIds] = useState<number[]>([])
  const [expandedRowContent, setExpandedRowContent] = useState<{
    [id: string]: ReactNode
  }>()
  const [checkedInactivePatients, setCheckedInactivePatients] = useState<boolean>(true)
  const [operatorFilters, setOperatorFilters] = useState<{ value: number, label: string }[]>([])
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const onChangeInactivePatientsToggle = (e: EuiSwitchEvent): void => {
    setCheckedInactivePatients(e.target.checked)
  }

  const onOperatorFilterChange = (selectedOptions: any[]): void => {
    setOperatorFilters(selectedOptions)
    console.log(selectedOptions)
  }

  const patientsData: Patient[] = useMemo(
    () => filterPatientsData(checkedInactivePatients, operatorFilters ?? [], dataProvider.patientsData),
    [checkedInactivePatients, operatorFilters, dataProvider.patientsData],
  )

  const operatorOptions: EuiComboBoxOptionOption<any>[] = useMemo(() => {
    return dataProvider.psychologistsData.map(psy => ({ 
      value: psy.id, 
      label: psy.attributes?.username ?? '-'
    }))
  }, [dataProvider.psychologistsData])

  useEffect(() => {
    if (!location.search) {
      return
    }

    const parsedSearchParams = queryString.parse(location.search)
    const idToExpand = parsedSearchParams?.expanded ?? ''

    const patientDetails = dataProvider.patientsData.find((patient) => patient.id === +idToExpand)
    if (!patientDetails || !patientDetails.attributes) {
      return
    }

    togglePatientDetails({
      id: patientDetails.id,
      ...patientDetails.attributes,
      attributes: patientDetails.attributes,
    })
  }, [location, dataProvider.patientsData])

  useEffect(() => {
    dataProvider.fetchAllPatients()
    dataProvider.fetchAllPsychologists()
  }, [])

  const navigateToPatientDetails = (patientId: number | undefined): void => {
    if (patientId === undefined) return

    navigate(
      generatePath(RoutePaths.PATIENT_EDIT, {
        id: patientId.toString(),
      }),
    )
  }

  const getRowProps = (item: Patient) => {
    const { id } = item
    return {
      'data-test-subj': `row-${id}`,
      className: 'customRowClass',
      onClick: () => {},
    }
  }

  const getCellProps = (
    item: Patient,
    column: { field: OverviewFields; name: OverviewHeaders },
  ) => {
    const { id } = item
    const { field } = column
    return {
      className: 'customCellClass',
      'data-test-subj': `cell-${id}-${field}`,
    }
  }

  const openPatientDetails = (patient: Patient): void => {
    navigateToPatientDetails(patient.id)
  }

  const togglePatientDetails = (patient: Patient): void => {
    if (!patient) return

    setExpandedRowIds((prevState: number[]) => {
      const toggledPatients: number[] = [...prevState]

      if (toggledPatients.includes(patient.id!)) {
        return toggledPatients.filter((id) => id !== patient.id!)
      } else {
        toggledPatients.push(patient.id!)
        return toggledPatients
      }
    })

    const content = { ...expandedRowContent }

    const listItems = overviewDataGridHiddenColumns.map((column: string) => {
      const columnData = overviewTableColumns.find((c: any) => c.field === `attributes.${column}`)
      const value = (patient as any)?.attributes?.[column]

      if (!columnData) {
        return {
          title: 'title',
          description: 'description',
        }
      }

      return {
        title: columnData ? <h2>{columnData.name}</h2> : <></>,
        description: value !== undefined ? <div>{renderCell(value, columnData.type!)}</div> : <></>,
      }
    })

    if (content[patient.id!]) {
      delete content[patient.id!]
    } else {
      content[patient.id!] = (
        <EuiDescriptionList
          className={styles.descriptionList}
          align='left'
          listItems={listItems as any[]}
        />
      )
    }

    setExpandedRowContent(content)
  }

  const actions: Action<Patient>[] = [
    {
      name: t('btns.details'),
      description: t('btns.detailsDescription'),
      icon: 'pencil',
      type: 'icon',
      color: 'primary',
      onClick: openPatientDetails,
    },
  ]

  const overviewColumns: EuiBasicTableColumn<Patient>[] = [
    ...overviewTableColumns.filter((c) => {
      return (
        (c as any).field.toLowerCase() === 'id' ||
        (c as any).field.toLowerCase() === 'index' ||
        overviewDataGridDefaultColumns.includes((c as any).field.split('.')?.[1])
      )
    }),
    {
      actions,
    },
    {
      align: 'center',
      width: '40px',
      isExpander: true,
      render: (item: Patient) => (
        <EuiButtonIcon
          aria-label={expandedRowIds.includes(item.id!) ? t('btns.collapse') : t('btns.expand')}
          iconType={expandedRowIds.includes(item.id!) ? 'arrowUp' : 'arrowDown'}
          onClick={() => togglePatientDetails(item)}
        />
      ),
    },
  ]

  const navigateToRegister = (): void => {
    navigate(RoutePaths.PATIENT_REGISTER)
  }

  return (
    <>
      <LoadingSpinner isVisible={dataProvider.isLoading} />

      <EuiFlexGroup alignItems='center' wrap={false} style={{ flexWrap: 'nowrap', padding: '0.5rem', gap: '0.5rem' }}>
        <EuiFlexItem component="span" grow={false} style={{ flexBasis: 0 }}>
          <EuiButtonIcon display='fill' iconType='plus' size='s' aria-label='addBtn' onClick={navigateToRegister} />
        </EuiFlexItem>
        <EuiFlexItem component="span" grow={false} style={{ flexBasis: 0 }}>
          <EuiButtonIcon display='fill' iconType='filterInclude' size='s' aria-label='filterBtn' onClick={() => setIsModalVisible(true)} />
        </EuiFlexItem>
      </EuiFlexGroup>

      {isModalVisible ? (
        <Modal
          title={t('overview.filter')}
          onClose={() => setIsModalVisible(false)}
          onSubmit={() => setIsModalVisible(false)}
        >
          <EuiFlexGroup direction='column' wrap={false} style={{ flexWrap: 'nowrap' }}>
            <EuiFlexItem component="span" grow={false}>
              <EuiSwitch
                label={OverviewFilters.TOGGLE_INACTIVE_PATIENTS}
                checked={checkedInactivePatients}
                onChange={onChangeInactivePatientsToggle}
                labelProps={{ style: { fontSize: '12px' } }}
              />
            </EuiFlexItem>
            <EuiFlexItem component="span" grow={false}>
              <EuiFormControlLayout style={{ minWidth: '200px', height: 'auto'}}>
                <EuiComboBox
                  className={styles.operatorFilter}
                  selectedOptions={operatorFilters}
                  options={operatorOptions}
                  onChange={onOperatorFilterChange}
                  placeholder='Избери оператор'
                />
                <EuiIcon type='arrowDown' className='dropdownIcon' />
              </EuiFormControlLayout>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Modal>
      ) : (
        <></>
      )}

      <section className={styles.container}>
        <EuiBasicTable
          className={styles.table}
          tableCaption='OverviewTable'
          items={patientsData}
          rowHeader={OverviewFields.ID}
          columns={overviewColumns}
          tableLayout='auto'
          isExpandable
          itemId='id'
          itemIdToExpandedRowMap={expandedRowContent}
          noItemsMessage={t('overview.noItemsMessage')}
          hasActions
          compressed
          rowProps={getRowProps}
          cellProps={getCellProps}
        />
      </section>
    </>
  )
}

export default Overview
