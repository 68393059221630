import { EuiComboBoxOptionOption } from '@elastic/eui'
import { Medication, MedicationLabels } from '../enums/medicationEnum'

export const medicationDropdownOptions: EuiComboBoxOptionOption<Medication>[] = [
  { value: Medication.ANTIDEPRESSANTS, label: MedicationLabels.ANTIDEPRESSANTS },
  { value: Medication.TIMOSTABILIZERS, label: MedicationLabels.TIMOSTABILIZERS },
  { value: Medication.TRANQUILIZERS, label: MedicationLabels.TRANQUILIZERS },
  { value: Medication.ANTIPSYCHOTICS, label: MedicationLabels.ANTIPSYCHOTICS },
  { value: Medication.NEUROLEPTICS, label: MedicationLabels.NEUROLEPTICS },
]
