/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { API_URL } from '.'

// import { getTokenRemainingLifeInMinutes } from '../utils/timeManagement'

axios.interceptors.request.use((config: any) => {
  // Do something before request is sent
  // const accessToken: string | null = localStorage.getItem('auth')
  // console.log('req interceptor', accessToken)

  //   const authStartDate: string | null = localStorage.getItem('authStartDate')
  //   const authExpireDate: string | null = localStorage.getItem('authExpireDate')
  //   if (authStartDate && authExpireDate) {
  //     const remainingTokenLifeInMinutes: number = getTokenRemainingLifeInMinutes(authExpireDate)
  //     // "REMAINING TOKEN LIFE TIME: ", remainingTokenLifeInMinutes);
  //     const criticalTokenLifeTime: number = 3
  //     // check if token is older than 12 mins and if yes - make a refresh call
  //     if (remainingTokenLifeInMinutes <= criticalTokenLifeTime) {
  //       // TODO: refresh token api call
  //     }
  //   }

  config.baseURL = API_URL

  return config
})

axios.interceptors.response.use(
  (response: any) => {
    // Do something with response data
    return new Promise((resolve) => resolve(response))
  },
  (error: any) => {
    // Global error handling
    // Do something with request error
    // console.log(error.response.status)

    // const authStartDate: string | null = localStorage.getItem('authStartDate')
    // const authExpireDate: string | null = localStorage.getItem('authExpireDate')
    // if (error.response.status === 401 && !!authStartDate && !!authExpireDate) {
    console.log(error)
    if (error?.response?.status === 401) {
      localStorage.removeItem('auth')
      localStorage.removeItem('user')
      // localStorage.removeItem('authStartDate')
      // localStorage.removeItem('authExpireDate')
      // window.location.href = '/'
    }

    return Promise.reject(error)
  },
)

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
  all: axios.all,
}

export default http
