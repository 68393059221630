export enum Medication {
  ANTIDEPRESSANTS = 'antidepressants',
  TIMOSTABILIZERS = 'timostabilizers',
  TRANQUILIZERS = 'tranquilizers',
  ANTIPSYCHOTICS = 'antipsychotics',
  NEUROLEPTICS = 'neuroleptics',
}

export enum MedicationLabels {
  ANTIDEPRESSANTS = 'Антидепресанти',
  TIMOSTABILIZERS = 'Тимостабилизатори',
  TRANQUILIZERS = 'Транквилизатори',
  ANTIPSYCHOTICS = 'Антипсихотици',
  NEUROLEPTICS = 'Невролептици',
  // ANTIDEPRESSANTS = 'Antidepressants',
  // TIMOSTABILIZERS = 'Timostabilizers',
  // TRANQUILIZERS = 'Tranquilizers',
  // ANTIPSYCHOTICS = 'Antipsychotics',
  // NEUROLEPTICS = 'Neuroleptics',
}
