import { useLocation, useNavigate } from 'react-router-dom'

import { EuiHeader, EuiHeaderSectionItem } from '@elastic/eui'
import { RoutePaths } from '../../shared/constants/enums/RoutePathsEnum'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import toastsList from '../../shared/constants/Toasts'
import { useAuth } from '../../store/auth/use-auth'
import Modal from '../Modal/Modal'
import Logo from './Logo/Logo'
import UserMenu from './UserMenu/UserMenu'

const Navbar = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const handleLogoClick = () => {
    navigate(RoutePaths.HOME)
  }

  const handleLogoutBtnClick = (): void => {
    auth.signOut(toastsList.signOut)
    navigate(RoutePaths.ROOT)
  }

  const handleProfileEditBtnClick = (): void => setIsModalVisible(true)

  const handleProfileModalClose = (): void => setIsModalVisible(false)

  const handleProfileChangesSubmit = (): void => {
    handleProfileModalClose()
  }

  return localStorage.getItem('auth') && location.pathname !== RoutePaths.ROOT ? (
    <>
      <EuiHeader>
        <EuiHeaderSectionItem>
          <Logo onClick={handleLogoClick} />
        </EuiHeaderSectionItem>

        <EuiHeaderSectionItem>
          <UserMenu
            onProfileEditBtnClick={handleProfileEditBtnClick}
            onLogoutBtnClick={handleLogoutBtnClick}
          />
        </EuiHeaderSectionItem>
      </EuiHeader>
      {isModalVisible ? (
        <Modal
          title={t('profile.title')}
          onClose={handleProfileModalClose}
          onSubmit={handleProfileChangesSubmit}
        >
          <span>{t('profile.placeholder')}</span>
          {/* {form(profileData)} */}
        </Modal>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  )
}

export default Navbar
