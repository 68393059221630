import ReactDOM from 'react-dom/client'
import { HashRouter } from 'react-router-dom'

import { EuiProvider } from '@elastic/eui'
import '@elastic/eui/dist/eui_theme_dark.css'

import App from './App'
import AuthVerify from './components/AuthVerify/AuthVerify'
import './i18n'
import reportWebVitals from './reportWebVitals'
import './shared/styles/index.scss'
import { AuthProvider } from './store/auth/use-auth'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <AuthProvider>
    <HashRouter>
      <EuiProvider colorMode='dark'>
        <App />
        <AuthVerify />
      </EuiProvider>
    </HashRouter>
  </AuthProvider>,
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
