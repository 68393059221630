/* eslint-disable @typescript-eslint/no-explicit-any */
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list'
import { OverviewFields } from '../../../shared/constants/enums/overviewDataGridColumnsEnum'

export const formatFormValidationErrors = (data: any): { [key in OverviewFields]?: string } => {
  const validationErrors = data?.error?.details?.errors
  if (!validationErrors) return {}

  const formattedErrors: { [key in OverviewFields]?: string } = {}

  validationErrors.forEach((error: any) => {
    if (error?.path?.length > 0) {
      const key: OverviewFields = error.path[0]
      const message = error.message
      formattedErrors[key] = message
    }
  })

  return formattedErrors
}

export const generateErrorToasts = (errors: any): Toast[] => {
  const errorToasts: Toast[] = []

  for (const key in errors) {
    if (errors[key] != undefined) {
      const toast: Toast = {
        id: `error-${key}`,
        title: errors[key],
        color: 'danger',
        iconType: 'alert',
      }
      errorToasts.push(toast)
    }
  }

  return errorToasts
}
