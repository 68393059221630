export const generateProgramOptions = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  psyPrograms: any[],
): {
  id: string
  label: string
  disabled: boolean
}[] => {
  return psyPrograms
    .map((psyProgram) => {
      const slotsLeft = psyProgram.max - psyProgram.used

      return {
        id: `${psyProgram.programId.id}`,
        label: `${psyProgram.programId.name} (${slotsLeft} места)`,
        disabled: slotsLeft === 0,
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
}
