import { Navigate } from 'react-router-dom'
import { RoutePaths } from '../../shared/constants/enums/RoutePathsEnum'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProtectedRoute = ({ isAuth, children }: any) => {
  if (isAuth === false) {
    return <Navigate to={RoutePaths.ROOT} replace />
  }

  return children
}

export default ProtectedRoute
