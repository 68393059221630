/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  EuiCheckbox,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFilePicker,
  EuiFormControlLayout,
  EuiFormRow,
  EuiIcon,
} from '@elastic/eui'
import { useTranslation } from 'react-i18next'
import { medicationDropdownOptions } from '../../shared/constants/dropdownOptions/medicationDropdownOptions'
import { Medication } from '../../shared/constants/enums/medicationEnum'
import {
  OverviewFields,
  OverviewHeaders,
} from '../../shared/constants/enums/overviewDataGridColumnsEnum'

interface MedicationInputsProps {
  isOnMedication: boolean | undefined
  medication: EuiComboBoxOptionOption<Medication>[] | undefined
  onInputChange: (e: any) => void
  onMedicationChange: (selectedOptions: any) => void
  onFileUpload: (files: FileList | null) => void
  errors: { [key in OverviewFields]?: string }
}

const MedicationInputs: React.FC<MedicationInputsProps> = (props: MedicationInputsProps) => {
  const { t } = useTranslation()

  return (
    <>
      <EuiFormRow label=''>
        <EuiCheckbox
          name={OverviewFields.IS_ON_MEDICATION}
          id='is-on-medication-checkbox-id'
          label={t('register.isOnMedication')}
          checked={props.isOnMedication}
          onChange={props.onInputChange}
        />
      </EuiFormRow>

      {props.isOnMedication ? (
        <>
          <EuiFormRow label={OverviewHeaders.MEDICATION}>
            <EuiFormControlLayout
              isInvalid={!!props.errors?.[OverviewFields.MEDICATION]}
              style={{ height: 'auto' }}
            >
              <EuiComboBox
                selectedOptions={props.medication}
                options={medicationDropdownOptions}
                onChange={props.onMedicationChange}
                isInvalid={!!props.errors?.[OverviewFields.MEDICATION]}
                isClearable
              />
              <EuiIcon type='arrowDown' className='dropdownIcon' />
            </EuiFormControlLayout>
          </EuiFormRow>
          <EuiFormRow label={OverviewHeaders.MEDICATION_RECIPE}>
            <EuiFormControlLayout isInvalid={!!props.errors?.[OverviewFields.MEDICATION_RECIPE]}>
              <EuiFilePicker
                id='medication-recipe-upload'
                name={OverviewFields.MEDICATION_RECIPE}
                display='default'
                multiple
                initialPromptText={t('register.filePickerPlaceholder')}
                onChange={props.onFileUpload}
              />
            </EuiFormControlLayout>
          </EuiFormRow>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default MedicationInputs
