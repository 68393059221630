export enum Languages {
  EN = 'en-US',
  BG = 'bg',
}

export enum LanguageLabels {
  EN = 'EN',
  BG = 'BG',
}

export const LNG_LOCAL_STORAGE_KEY = 'i18nextLng'
