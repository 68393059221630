export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export enum GenderLabels {
  MALE = 'Мъж',
  FEMALE = 'Жена',
  OTHER = 'Друг',
  // MALE = 'Male',
  // FEMALE = 'Female',
  // OTHER = 'Other',
}
