import { EuiGlobalToastList } from '@elastic/eui'
import React from 'react'

import { Toast } from '@elastic/eui/src/components/toast/global_toast_list'
import { useData } from '../../store/data/dataProvider'

const ToastNotificator: React.FC = (): JSX.Element => {
  const dataProvider = useData()!

  const dismissToast = (toast: Toast): void => {
    dataProvider.setToasts((prevToasts: Toast[]) => prevToasts.filter((t) => t.id !== toast.id))
  }

  return (
    <EuiGlobalToastList
      toasts={dataProvider.toasts}
      dismissToast={dismissToast}
      toastLifeTimeMs={3000}
    />
  )
}

export default ToastNotificator
