/* eslint-disable @typescript-eslint/no-explicit-any */
import { EuiBasicTableColumn, EuiButtonIcon, EuiDataGridColumn, EuiHealth, EuiImage } from '@elastic/eui'
import { API_URL } from '../../../../http'
import { diagnosisDropdownOptions } from '../../../../shared/constants/dropdownOptions/diagnosisDropdownOptions'
import { genderDropdownOptions } from '../../../../shared/constants/dropdownOptions/genderDropdownOptions'
import { medicationDropdownOptions } from '../../../../shared/constants/dropdownOptions/medicationDropdownOptions'
import {
  OverviewFields,
  OverviewHeaders,
} from '../../../../shared/constants/enums/overviewDataGridColumnsEnum'
import { TableCellTypes } from '../../../../shared/constants/enums/tableCellTypeEnum'
import { Patient } from '../../../../shared/models/Patient.model'
import { Psychologist } from '../../../../shared/models/Psychologist.model'
import { formatDateTime } from '../../../../shared/utils/utilFunctions'
import { NO_EMAIL_PLACEHOLDER } from '../../Register/Register'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderCell = (content: any, type: TableCellTypes, field?: OverviewFields) => {
  if (type === TableCellTypes.DATE_TIME) {
    return formatDateTime(`${content}`)
  }

  if (type === TableCellTypes.BOOLEAN) {
    const color = content ? 'success' : 'danger'
    const label = content ? 'Да' : 'Не'
    return (
      <EuiHealth textSize='xs' color={color}>
        {label}
      </EuiHealth>
    )
  }

  if (type === TableCellTypes.ENUM) {
    if (field === OverviewFields.DIAGNOSIS) {
      return diagnosisDropdownOptions.find((diagnosis) => diagnosis.value === content)?.text
    }

    if (field === OverviewFields.GENDER) {
      return genderDropdownOptions.find((gender) => gender.value === content)?.text
    }
  }

  if (type === TableCellTypes.MULTI) {
    if (field === OverviewFields.MEDICATION) {
      if (!content) return ''
      if (typeof content === 'string') {
        return content
          .split(';')
          .map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (c: any) =>
              medicationDropdownOptions.find((medication) => medication.value === c)?.label,
          )
          .join(', ')
      }

      return content
        .map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (c: any) => medicationDropdownOptions.find((medication) => medication.value === c)?.label,
        )
        .join(', ')
    }
  }

  if (type === TableCellTypes.RELATION) {
    if (field === OverviewFields.OPERATOR) {
      return (content as Psychologist)?.attributes?.username
    }

    if (field === OverviewFields.PROGRAM) {
      const programName: string = content?.[0]?.attributes?.programId?.attributes?.name
      return programName
    }

    if (field === OverviewFields.PROCEDURES) {
      const procedures: number = content?.[0]?.attributes?.count
      return procedures
    }

    if (field === OverviewFields.VISITS) {
      const procedures: number = content?.[0]?.attributes?.visits
      return procedures
    }
  }

  if (type === TableCellTypes.IMAGE) {
    if (!content || !content.length) return <></>

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return content.map((c: any) => (
      <EuiImage
        key={c.id}
        hasShadow
        allowFullScreen
        size={25}
        alt='Рецепти'
        src={`${API_URL}${c.attributes?.url}`}
      />
    ))
  }
  
  if (type === TableCellTypes.DUMMY_EMAIL) {
    if (content?.includes(NO_EMAIL_PLACEHOLDER)) {
      return '-'
    }

    return content;
  }

  if (type === TableCellTypes.STRING) {
    if (!content) {
      return '-'
    }

    return content;

  }

  if (type === TableCellTypes.LINK) {
    const isDisabled: any = !content;

    if (field === OverviewFields.RESULTS_LINK) {
      return (
        <EuiButtonIcon 
          href={content} 
          target='_blank' 
          rel='noreferrer' 
          iconType='document' 
          aria-label='tableResults' 
          disabled={isDisabled} 
        />
      );
    }

    if (field === OverviewFields.CHART_RESULTS_LINK) {
      return (
        <EuiButtonIcon 
          href={content} 
          target='_blank' 
          rel='noreferrer' 
          iconType='visBarVertical' 
          aria-label='tableResults' 
          disabled={isDisabled} 
        />
      );
    }

    return content
  }

  return content
}

const desktopColumnBasicOptions: {
  sortable: boolean
  truncateText: boolean
  valign: 'top' | 'middle' | 'bottom' | 'baseline' | undefined
} = {
  sortable: false,
  truncateText: false,
  valign: 'middle',
}

const mobileColumnBasicOptions = {
  header: true,
  truncateText: false,
}

type TableColumn = EuiBasicTableColumn<Patient> & { type?: TableCellTypes }

export const overviewTableColumns: TableColumn[] = [
  {
    field: OverviewFields.INDEX,
    name: OverviewHeaders.INDEX,
    ...desktopColumnBasicOptions,
    truncateText: true,
  },
  {
    field: OverviewFields.ID,
    name: OverviewHeaders.ID,
    ...desktopColumnBasicOptions,
    truncateText: true,
  },
  {
    field: `attributes.${OverviewFields.FIRSTNAME}`,
    name: OverviewHeaders.FIRSTNAME,
    ...desktopColumnBasicOptions,
    mobileOptions: { ...mobileColumnBasicOptions, enlarge: true, width: '100%' },
  },
  {
    field: `attributes.${OverviewFields.LASTNAME}`,
    name: OverviewHeaders.LASTNAME,
    ...desktopColumnBasicOptions,
    mobileOptions: { ...mobileColumnBasicOptions, enlarge: true, width: '100%' },
  },
  {
    field: `attributes.${OverviewFields.AGE}`,
    name: OverviewHeaders.AGE,
    ...desktopColumnBasicOptions,
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.DIAGNOSIS}`,
    name: OverviewHeaders.DIAGNOSIS,
    type: TableCellTypes.ENUM,
    ...desktopColumnBasicOptions,
    render: (content: boolean) =>
      renderCell(content, TableCellTypes.ENUM, OverviewFields.DIAGNOSIS),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.MEDICATION}`,
    name: OverviewHeaders.MEDICATION,
    type: TableCellTypes.MULTI,
    ...desktopColumnBasicOptions,
    render: (content: boolean) =>
      renderCell(content, TableCellTypes.MULTI, OverviewFields.MEDICATION),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.MEDICATION_RECIPE}`,
    name: 'Рецепти',
    type: TableCellTypes.IMAGE,
    ...desktopColumnBasicOptions,
    render: (content: any) =>
      renderCell(content, TableCellTypes.IMAGE, OverviewFields.MEDICATION_RECIPE),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.OPERATOR}`,
    name: OverviewHeaders.OPERATOR,
    type: TableCellTypes.RELATION,
    ...desktopColumnBasicOptions,
    render: (content: any) => renderCell(content, TableCellTypes.RELATION, OverviewFields.OPERATOR),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.PROGRAM}`,
    name: OverviewHeaders.PROGRAM,
    type: TableCellTypes.RELATION,
    ...desktopColumnBasicOptions,
    render: (content: any[]) =>
      renderCell(content, TableCellTypes.RELATION, OverviewFields.PROGRAM),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.PROGRAM}`,
    name: OverviewHeaders.PROCEDURES,
    type: TableCellTypes.RELATION,
    ...desktopColumnBasicOptions,
    render: (content: any[]) =>
      renderCell(content, TableCellTypes.RELATION, OverviewFields.PROCEDURES),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.PROGRAM}`,
    name: OverviewHeaders.VISITS,
    type: TableCellTypes.RELATION,
    ...desktopColumnBasicOptions,
    render: (content: any[]) => renderCell(content, TableCellTypes.RELATION, OverviewFields.VISITS),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.ACTIVE}`,
    name: OverviewHeaders.ACTIVE,
    type: TableCellTypes.BOOLEAN,
    ...desktopColumnBasicOptions,
    render: (content: boolean) => renderCell(content, TableCellTypes.BOOLEAN),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.GENDER}`,
    name: OverviewHeaders.GENDER,
    type: TableCellTypes.ENUM,
    ...desktopColumnBasicOptions,
    render: (content: boolean) => renderCell(content, TableCellTypes.ENUM, OverviewFields.GENDER),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.PHONE_NUMBER_PREFIX}`,
    name: OverviewHeaders.PHONE_NUMBER_PREFIX,
    ...desktopColumnBasicOptions,
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.PHONE_NUMBER}`,
    name: OverviewHeaders.PHONE_NUMBER,
    ...desktopColumnBasicOptions,
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.EMAIL}`,
    name: OverviewHeaders.EMAIL,
    type: TableCellTypes.DUMMY_EMAIL,
    ...desktopColumnBasicOptions,
    render: (content: boolean) => renderCell(content, TableCellTypes.DUMMY_EMAIL),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.NOTE}`,
    name: OverviewHeaders.NOTE,
    ...desktopColumnBasicOptions,
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.IS_ANONYMIZED}`,
    name: OverviewHeaders.IS_ANONYMIZED,
    type: TableCellTypes.BOOLEAN,
    ...desktopColumnBasicOptions,
    render: (content: boolean) => renderCell(content, TableCellTypes.BOOLEAN),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.IS_CONFIRMED}`,
    name: OverviewHeaders.IS_CONFIRMED,
    type: TableCellTypes.BOOLEAN,
    ...desktopColumnBasicOptions,
    render: (content: boolean) => renderCell(content, TableCellTypes.BOOLEAN),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.VISITED_OPERATOR}`,
    name: OverviewHeaders.VISITED_OPERATOR,
    type: TableCellTypes.BOOLEAN,
    ...desktopColumnBasicOptions,
    render: (content: boolean) => renderCell(content, TableCellTypes.BOOLEAN),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.CREATED_AT}`,
    name: OverviewHeaders.CREATED_AT,
    type: TableCellTypes.DATE_TIME,
    ...desktopColumnBasicOptions,
    render: (content: string) => renderCell(content, TableCellTypes.DATE_TIME),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.UPDATED_AT}`,
    name: OverviewHeaders.UPDATED_AT,
    type: TableCellTypes.DATE_TIME,
    ...desktopColumnBasicOptions,
    render: (content: string) => renderCell(content, TableCellTypes.DATE_TIME),
    mobileOptions: mobileColumnBasicOptions,
  },
  {
    field: `attributes.${OverviewFields.RESULTS_LINK}`,
    name: '',
    type: TableCellTypes.LINK,
    ...desktopColumnBasicOptions,
    render: (content: string) => renderCell(content, TableCellTypes.LINK, OverviewFields.RESULTS_LINK),
    mobileOptions: mobileColumnBasicOptions,
    align: 'center',
  },
  {
    field: `attributes.${OverviewFields.CHART_RESULTS_LINK}`,
    name: '',
    type: TableCellTypes.LINK,
    ...desktopColumnBasicOptions,
    render: (content: string) => renderCell(content, TableCellTypes.LINK, OverviewFields.CHART_RESULTS_LINK),
    mobileOptions: mobileColumnBasicOptions,
    align: 'center',
  },
]

export const overviewDataGridColumns: EuiDataGridColumn[] = [
  {
    id: OverviewFields.ID,
    displayAsText: OverviewHeaders.ID,
    schema: 'number',
  },
  {
    id: OverviewFields.FIRSTNAME,
    displayAsText: OverviewHeaders.FIRSTNAME,
    schema: 'string',
  },
  {
    id: OverviewFields.LASTNAME,
    displayAsText: OverviewHeaders.LASTNAME,
    schema: 'string',
  },
  {
    id: OverviewFields.AGE,
    displayAsText: OverviewHeaders.AGE,
    schema: 'number',
  },
  {
    id: OverviewFields.GENDER,
    displayAsText: OverviewHeaders.GENDER,
    schema: 'enum',
  },
  {
    id: OverviewFields.PHONE_NUMBER_PREFIX,
    displayAsText: OverviewHeaders.PHONE_NUMBER_PREFIX,
    schema: 'string',
  },
  {
    id: OverviewFields.PHONE_NUMBER,
    displayAsText: OverviewHeaders.PHONE_NUMBER,
    schema: 'string',
  },
  {
    id: OverviewFields.EMAIL,
    displayAsText: OverviewHeaders.EMAIL,
    schema: 'string',
  },
  {
    id: OverviewFields.OPERATOR,
    displayAsText: OverviewHeaders.OPERATOR,
    schema: 'rel',
  },
  {
    id: OverviewFields.DIAGNOSIS,
    displayAsText: OverviewHeaders.DIAGNOSIS,
    schema: 'enum',
  },
  {
    id: OverviewFields.NOTE,
    displayAsText: OverviewHeaders.NOTE,
    schema: 'string',
  },
  {
    id: OverviewFields.IS_ANONYMIZED,
    displayAsText: OverviewHeaders.IS_ANONYMIZED,
    schema: 'boolean',
  },
  {
    id: OverviewFields.IS_CONFIRMED,
    displayAsText: OverviewHeaders.IS_CONFIRMED,
    schema: 'boolean',
  },
  {
    id: OverviewFields.VISITED_OPERATOR,
    displayAsText: OverviewHeaders.VISITED_OPERATOR,
    schema: 'boolean',
  },
  {
    id: OverviewFields.CREATED_AT,
    displayAsText: OverviewHeaders.CREATED_AT,
    schema: 'datetime',
  },
  {
    id: OverviewFields.UPDATED_AT,
    displayAsText: OverviewHeaders.UPDATED_AT,
    schema: 'datetime',
  },
]

export const overviewDataGridDefaultColumns: string[] = [
  OverviewFields.INDEX,
  OverviewFields.ID,
  OverviewFields.FIRSTNAME,
  OverviewFields.LASTNAME,
  OverviewFields.AGE,
  OverviewFields.DIAGNOSIS,
  OverviewFields.MEDICATION,
  OverviewFields.MEDICATION_RECIPE,
  OverviewFields.OPERATOR,
  OverviewFields.PROGRAM,
  OverviewFields.PROCEDURES,
  OverviewFields.ACTIVE,
  OverviewFields.IS_CONFIRMED,
  OverviewFields.RESULTS_LINK,
  OverviewFields.CHART_RESULTS_LINK
]

export const overviewDataGridHiddenColumns: string[] = [
  OverviewFields.GENDER,
  OverviewFields.PHONE_NUMBER_PREFIX,
  OverviewFields.PHONE_NUMBER,
  OverviewFields.EMAIL,
  OverviewFields.IS_ANONYMIZED,
  OverviewFields.VISITED_OPERATOR,
  OverviewFields.NOTE,
  OverviewFields.CREATED_AT,
  OverviewFields.UPDATED_AT,
]
