/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { RoutePaths } from '../../shared/constants/enums/RoutePathsEnum'
import { useAuth } from '../../store/auth/use-auth'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthVerify = (props: any) => {
  const auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth')
    auth.setIsAuth(!!isAuthenticated)
    // TODO: adjust when it's known when the session expires
    // const authExpireDate: string | null = localStorage.getItem('authExpireDate')

    if (location.pathname === RoutePaths.ROOT && isAuthenticated) {
      navigate(RoutePaths.HOME)
    }

    if (location.pathname !== RoutePaths.ROOT && !isAuthenticated) {
      auth.signOut()
      navigate(RoutePaths.ROOT)
    }

    // TODO: adjust when it's known when the session expires
    // if (!authExpireDate || getTokenRemainingLifeInMinutes(authExpireDate) <= 0) {
    //   auth.signOut()
    //   // auth.signOut(homeToasts.sessionExpired)
    //   navigate(RoutePaths.ROOT)
    // }
  }, [location])

  return <></>
}

export default AuthVerify
