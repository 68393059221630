import { EuiHeaderLogo } from '@elastic/eui'
import { useTranslation } from 'react-i18next'

interface LogoProps {
  onClick: () => void
}

const Logo: React.FC<LogoProps> = (props: LogoProps) => {
  const { t } = useTranslation()

  return (
    <>
      <EuiHeaderLogo
        key='home-btn'
        onClick={props.onClick}
        iconType='logoObservability'
        style={{ cursor: 'pointer' }}
        color='#36a2ef'
      >
        {t('nav.home')}
      </EuiHeaderLogo>
    </>
  )
}

export default Logo
