import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import bgTranslation from './locales/bg/translations.json'
import enTranslation from './locales/en/translations.json'
import { Languages } from './shared/constants/enums/languagesEnum'

const resources = {
  [Languages.EN]: { translation: enTranslation },
  [Languages.BG]: { translation: bgTranslation },
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    debug: false,
    // detection: {
    //   order: ['localStorage', 'navigator'],
    //   caches: ['localStorage'],
    // },
    // lng: localStorage.getItem(LNG_LOCAL_STORAGE_KEY) || Languages.BG,
    lng: Languages.BG,
    fallbackLng: Languages.BG,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
