/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useState } from 'react'

import { Toast } from '@elastic/eui/src/components/toast/global_toast_list'
import patientDetailsToasts from '../../containers/Patient/Details/utils/Toasts'
import patientsOverviewToasts from '../../containers/Patient/Overview/utils/Toasts'
import patientRegisterToasts from '../../containers/Patient/Register/utils/Toasts'
import { API_URL } from '../../http'
import http from '../../http/httpsService'
import { OverviewFields } from '../../shared/constants/enums/overviewDataGridColumnsEnum'
import { Patient } from '../../shared/models/Patient.model'
import { Psychologist } from '../../shared/models/Psychologist.model'
import { PsychologistProgram } from '../../shared/models/PsychologistProgram.model'
import { generatePatientPostBody } from './utils/bodyGenerators'
import { formatFormValidationErrors } from './utils/handleFormValidationErrors'

interface IAppDataContext {
  patientsData: Patient[]
  patientData: Patient | null
  psychologistsData: Psychologist[]
  createdPatient: Patient | null
  programs: PsychologistProgram[]
  patientPrograms: any[]
  psychologistPrograms: any[]
  errors: { [key in OverviewFields]?: string }
  isLoading: boolean
  toasts: Toast[]
  setToasts: React.Dispatch<React.SetStateAction<Toast[]>>
  setErrors: React.Dispatch<React.SetStateAction<{ [key in OverviewFields]?: string }>>
  setPatientData: React.Dispatch<React.SetStateAction<Patient | null>>
  fetchAllPatients: () => void
  fetchSinglePatient: (id: number) => void
  createPatient: (patientData: Patient, callback: (success: boolean) => void) => void
  updatePatient: (id: number, patientData: any, callback: (success: boolean) => void) => void
  createPatientProgram: (patientProgramData: any) => void
  updatePatientProgram: (id: number, patientProgramData: any) => void
  fetchAllPrograms: () => void
  fetchAllPatientPrograms: () => void
  fetchAllPsychologists: () => void
  fetchSinglePsychologistPrograms: (psychologistId: number) => void
  updatePsychologistProgram: (id: number, psyProgramData: any) => void
  fetchPatientDetails: (patientId: number) => void
  fetchPatientComposeDetails: (patientId?: string) => void
  updatePatientDetails: (id: number, patientData: any, patientProgram: any) => void
}

export const AppDataContext = createContext<IAppDataContext | null>(null)

export const DataProvider = ({ children }: { children: any }) => {
  const data = useDataProvider()

  return <AppDataContext.Provider value={data}>{children}</AppDataContext.Provider>
}

export const useData = () => {
  return useContext(AppDataContext)
}

const useDataProvider = () => {
  const [patientsData, setPatientsData] = useState<Patient[]>([])
  const [patientData, setPatientData] = useState<Patient | null>(null)
  const [createdPatient, setCreatedPatient] = useState<Patient | null>(null)
  const [programs, setPrograms] = useState<PsychologistProgram[]>([])
  const [patientPrograms, setPatientPrograms] = useState<any[]>([])
  const [psychologistsData, setPsychologistsData] = useState<Psychologist[]>([])
  const [psychologistPrograms, setPsychologistPrograms] = useState<any[]>([])
  const [errors, setErrors] = useState<{ [key in OverviewFields]?: string }>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [toasts, setToasts] = useState<Toast[]>([])

  const updateToasts = (toast: Toast): void => {
    const isDuplicate: boolean = toasts.some((t) => t.id === toast.id)
    if (isDuplicate) return

    setToasts((prevToasts: Toast[]) => [...prevToasts, toast])
  }

  const token = localStorage.getItem('auth')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  function fetchAllPatients(): void {
    setIsLoading(true)

    http
      .get(`${API_URL}/api/v1/patients`, {
        headers: headers,
      })
      .then((response) => {
        const { data: patientsData } = response.data
        if (patientsData !== undefined) {
          setPatientsData(patientsData.map((p: Patient, index: number) => ({...p, index: index + 1 })))
          updateToasts(patientsOverviewToasts.loadPatientsOverviewSuccess)
        } else {
          updateToasts(patientsOverviewToasts.loadPatientsOverviewFailed)
        }
      })
      .catch((error) => {
        console.log('error: ', error)
        setToasts((prevToasts: Toast[]) => [
          ...prevToasts,
          patientsOverviewToasts.loadPatientsOverviewFailed,
        ])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // unused
  function fetchSinglePatient(id: number): void {
    setIsLoading(true)

    http
      .get(`${API_URL}/api/v1/patients/${id}?populate=*`, { headers: headers })
      .then(function (response) {
        const { data: patientData } = response.data
        if (patientData !== undefined) {
          setPatientData(patientData)
        }
      })
      .catch(function (error) {
        console.log('error: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function createPatient(patientData: Patient, callback: (success: boolean) => void): void {
    setCreatedPatient(null)
    setErrors({})
    setIsLoading(true)

    const body = generatePatientPostBody(patientData)

    http
      .post(`${API_URL}/api/v1/patients/register`, body, {
        headers: {
          Authorization: headers.Authorization,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.statusText) {
          updateToasts(patientRegisterToasts.createPatientSuccess)
          callback(true)
        } else {
          updateToasts(patientRegisterToasts.createPatientFailed)
          callback(false)
        }
      })
      .catch((error) => {
        console.log('error: ', error)
        setErrors(formatFormValidationErrors(error.response.data))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function updatePatient(
    id: number,
    patientData: Patient,
    callback: (success: boolean) => void,
  ): void {
    setErrors({})
    setIsLoading(true)

    const body = generatePatientPostBody(patientData)

    http
      .post(`${API_URL}/api/v1/patient-compose/${id}`, body, {
        headers: {
          Authorization: headers.Authorization,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.statusText) {
          updateToasts(patientRegisterToasts.createPatientSuccess)
          callback(true)
        } else {
          updateToasts(patientRegisterToasts.createPatientFailed)
          callback(false)
        }
      })
      .catch((error) => {
        console.log('error: ', error)
        setErrors(formatFormValidationErrors(error.response.data))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // unused
  function createPatientProgram(patientProgramData: any): void {
    setIsLoading(true)
    const body = { data: patientProgramData }

    http
      .post(`${API_URL}/api/v1/patient-programs`, JSON.stringify(body), {
        headers: headers,
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response) => {})
      .catch((error) => {
        console.log('error: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // unused
  function updatePatientProgram(id: number, patientProgramData: any): void {
    setIsLoading(true)
    const body = { data: patientProgramData }

    http
      .put(`${API_URL}/api/v1/patient-programs/${id}`, JSON.stringify(body), {
        headers: headers,
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response) => {})
      .catch((error) => {
        console.log('error: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function fetchAllPrograms(): void {
    setIsLoading(true)

    http
      .get(`${API_URL}/api/v1/programs`, { headers: headers })
      .then(function (response) {
        const { data: programsData } = response.data
        if (programsData !== undefined) {
          setPrograms(programsData)
        }
        setIsLoading(false)
      })
      .catch(function (error) {
        console.log('error: ', error)
        setIsLoading(false)
      })
  }

  function fetchAllPsychologists(): void {
    setIsLoading(true)

    http
      .get(`${API_URL}/api/v1/patients/operators`, { headers: headers })
      .then((response) => {
        const { data: psychologistsData } = response.data
        if (response.data !== undefined) {
          setPsychologistsData(psychologistsData)
        }
      })
      .catch((error) => {
        console.log('error: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function fetchSinglePsychologistPrograms(psychologistId: number): void {
    setIsLoading(true)

    http
      .get(`${API_URL}/api/v1/patients/operators/${psychologistId}/programs`, { headers: headers })
      .then((response) => {
        const { data: psychologistProgramsData } = response.data
        if (psychologistProgramsData !== undefined) {
          setPsychologistPrograms(
            psychologistProgramsData.map((entry: any) => ({
              id: entry.id,
              ...entry.attributes,
            })),
          )
        }
      })
      .catch((error) => {
        console.log('error: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // unused
  function updatePsychologistProgram(id: number, psyProgramData: any): void {
    setIsLoading(true)
    const body = { data: psyProgramData }

    http
      .put(`${API_URL}/api/v1/psychologist-programs/${id}`, JSON.stringify(body), {
        headers: headers,
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response) => {})
      .catch((error) => {
        console.log('error: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // unused
  function fetchAllPatientPrograms(): void {
    setIsLoading(true)

    http
      .get(`${API_URL}/api/v1/patient-programs?populate=*`, { headers: headers })
      .then((response) => {
        const { data: patientProgramsData } = response.data
        if (patientProgramsData !== undefined) {
          setPatientPrograms(patientProgramsData)
        }
      })
      .catch((error) => {
        console.log('error: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function fetchPatientComposeDetails(patientId?: string): void {
    setIsLoading(true)

    const ENDPOINT_URL = patientId
      ? `/api/v1/patient-compose/${patientId}` // called when editing a patient
      : '/api/v1/patient-compose' // called when creating a patient

    http
      .get(`${API_URL}${ENDPOINT_URL}`, { headers: headers })
      .then((response) => {
        const { data } = response.data
        if (data !== undefined) {
          const { patientData, allPatientPrograms, allPrograms, assignedPsychologistData } =
            data.attributes

          setPatientData(patientData)
          setPrograms(allPrograms)
          setPatientPrograms(allPatientPrograms)
          setPsychologistsData(assignedPsychologistData)
        }
      })
      .catch((error) => {
        console.log('error: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function fetchPatientDetails(id: number): void {
    setIsLoading(true)

    http
      .get(`${API_URL}/api/v1/patients/${id}/details`, { headers: headers })
      .then((response) => {
        const { data } = response.data
        if (data !== undefined) {
          const { patientData, allPatientPrograms, allPrograms, assignedPsychologistData } =
            data.attributes

          setPatientData(patientData)
          setPrograms(allPrograms)
          setPatientPrograms(allPatientPrograms)
          setPsychologistsData(assignedPsychologistData)
        }
      })
      .catch((error) => {
        console.log('error: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function updatePatientDetails(
    id: number,
    patientData: Partial<Patient>,
    patientProgram: { id?: number; programId: number; count: number },
  ): void {
    setIsLoading(true)

    const body = generatePatientPostBody({
      ...patientData,
      programId: patientProgram.programId,
      count: patientProgram.count,
    })

    http
      .post(`${API_URL}/api/v1/patients/${id}/details`, body, {
        headers: {
          Authorization: headers.Authorization,
          'Content-Type': 'multipart/form-data',
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response) => {
        updateToasts(patientDetailsToasts.updatePatientSuccess)
      })
      .catch((error) => {
        console.log('error: ', error)
        updateToasts(patientDetailsToasts.updatePatientFailed)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    patientsData,
    patientData,
    psychologistsData,
    createdPatient,
    programs,
    patientPrograms,
    psychologistPrograms,
    errors,
    isLoading,
    toasts,
    setToasts,
    setErrors,
    setPatientData,
    fetchAllPatients,
    fetchSinglePatient,
    createPatient,
    createPatientProgram,
    updatePatientProgram,
    updatePatient,
    fetchAllPrograms,
    fetchAllPatientPrograms,
    fetchAllPsychologists,
    fetchSinglePsychologistPrograms,
    updatePsychologistProgram,
    fetchPatientDetails,
    fetchPatientComposeDetails,
    updatePatientDetails,
  }
}
