import { EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem } from '@elastic/eui'
import { useNavigate } from 'react-router-dom'

import { RoutePaths } from '../../shared/constants/enums/RoutePathsEnum'
import { HomeNavLabels } from '../../shared/constants/enums/homeNavLabelsEnum'
import styles from './Home.module.scss'

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const navigate = useNavigate()

  const toOverview = () => {
    navigate(RoutePaths.PATIENT_OVERVIEW)
  }

  const toRegister = () => {
    navigate(RoutePaths.PATIENT_REGISTER)
  }

  return (
    <nav aria-label='Nav title' className={`container--center ${styles.container}`}>
      <EuiKeyPadMenu className={styles.menu}>
        <EuiKeyPadMenuItem
          label={HomeNavLabels.OVERVIEW}
          className={styles.menu__item}
          onClick={toOverview}
        >
          <EuiIcon type='list' size='xxl' />
        </EuiKeyPadMenuItem>
        <EuiKeyPadMenuItem
          label={HomeNavLabels.REGISTER}
          className={styles.menu__item}
          onClick={toRegister}
        >
          <EuiIcon type='plus' size='xxl' />
        </EuiKeyPadMenuItem>
      </EuiKeyPadMenu>
    </nav>
  )
}

export default Home
