import { Patient } from '../../../../shared/models/Patient.model';
import { Psychologist } from '../../../../shared/models/Psychologist.model';

export const filterPatientsData = (checkedInactivePatients: boolean, operatorIds: any[], patientsData: Patient[]) => {
  const filteredByActive: Patient[] = checkedInactivePatients ? [...patientsData] : patientsData.filter(p => p.attributes?.active);

  if (!operatorIds.length) {
    return filteredByActive;
  }

  return filteredByActive.filter((patient: Patient) => {
    return operatorIds.map(id => id.label).includes((patient.attributes?.operatorId as Psychologist)?.attributes?.username);
  })
}
