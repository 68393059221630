import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiLink,
  EuiPopover,
  EuiSpacer,
  EuiText,
  useGeneratedHtmlId,
} from '@elastic/eui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { APP_VERSION } from '../../../http'

interface UserMenuProps {
  onProfileEditBtnClick: () => void
  onLogoutBtnClick: () => void
}

const UserMenu: React.FC<UserMenuProps> = (props: UserMenuProps) => {
  const { t } = useTranslation()
  const headerUserPopoverId = useGeneratedHtmlId({
    prefix: 'headerUserPopover',
  })
  const [isOpen, setIsOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [userData, setUserData] = useState<any>(null)

  useEffect(() => {
    const userData = localStorage.getItem('user')
    if (!userData) return
    setUserData(JSON.parse(userData))
  }, [])

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={headerUserPopoverId}
      aria-expanded={isOpen}
      aria-haspopup='true'
      aria-label='Account menu'
      onClick={onMenuButtonClick}
    >
      <EuiAvatar name={userData?.username ?? '6th'} color='#36a2ef' size='s' />
    </EuiHeaderSectionItemButton>
  )

  return (
    <EuiPopover
      id={headerUserPopoverId}
      button={button}
      isOpen={isOpen}
      anchorPosition='downRight'
      closePopover={closeMenu}
      panelPaddingSize='m'
    >
      <div style={{ width: 300 }}>
        <EuiFlexGroup gutterSize='m' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiAvatar name={userData?.username ?? ''} size='xl' color='#36a2ef' />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <p>{userData?.username ?? '6th потребител'}</p>
              <p>v{APP_VERSION}</p>
            </EuiText>

            <EuiSpacer size='m' />

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFlexGroup justifyContent='spaceBetween'>
                  <EuiFlexItem grow={false}>
                    <EuiLink onClick={props.onProfileEditBtnClick}>
                      {t('profile.editProfile')}
                    </EuiLink>
                  </EuiFlexItem>

                  <EuiFlexItem grow={false}>
                    <EuiLink onClick={props.onLogoutBtnClick}>{t('profile.logout')}</EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  )
}

export default UserMenu
