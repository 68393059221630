import { Toast } from '@elastic/eui/src/components/toast/global_toast_list'

const toastsList: { [key: string]: Toast } = {
  signIn: {
    id: 'success-signin',
    title: 'Welcome to Yoshi Client Portal!',
    color: 'success',
    iconType: 'check',
  },
  signOut: {
    id: 'success-signout',
    title: 'You have beed logged out.',
    color: 'success',
    iconType: 'check',
    text: 'See you soon!',
  },
  sessionExpired: {
    id: 'session-finish-signout',
    title: 'You have been logged out.',
    color: 'warning',
    iconType: 'check',
    text: 'The current session has expired.',
  },
}

export default toastsList
