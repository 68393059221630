/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { OverviewFields } from '../../../shared/constants/enums/overviewDataGridColumnsEnum'

export const generatePatientPostBody = (patientData: any): FormData => {
  const { medicationRecipe, ...patientBodyData } = patientData
  const body = new FormData()

  for (const entry in patientBodyData) {
    if (entry === OverviewFields.MEDICATION) {
      body.append(entry, (patientBodyData as any)[entry].join(';'))
    } else {
      body.append(entry, (patientBodyData as any)[entry])
    }
  }

  if (medicationRecipe) {
    Array.from(medicationRecipe).forEach((recipe: any) => {
      body.append(OverviewFields.MEDICATION_RECIPE, recipe, recipe.name)
    })
  }

  return body
}
