/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react'

import {
  EuiBadge,
  EuiButton,
  EuiCheckbox,
  EuiFieldNumber,
  EuiFieldText,
  EuiForm,
  EuiFormControlLayout,
  EuiFormRow,
  EuiIcon,
  EuiPageHeader,
  EuiRadioGroup,
  EuiSelect,
  EuiTextArea,
} from '@elastic/eui'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import MedicationInputs from '../../../components/MedicationInputs/MedicationInputs'
import { diagnosisDropdownOptions } from '../../../shared/constants/dropdownOptions/diagnosisDropdownOptions'
import { genderDropdownOptions } from '../../../shared/constants/dropdownOptions/genderDropdownOptions'
import { medicationDropdownOptions } from '../../../shared/constants/dropdownOptions/medicationDropdownOptions'
import { phoneNumberPrefixDropdownOptions } from '../../../shared/constants/dropdownOptions/phoneNumberPrefixDropdownOptions'
import { RoutePaths } from '../../../shared/constants/enums/RoutePathsEnum'
import { AnonymizedPatientData } from '../../../shared/constants/enums/anonymizedPatientDataEnum'
import { Diagnosis } from '../../../shared/constants/enums/diagnosisEnum'
import { Gender } from '../../../shared/constants/enums/genderEnum'
import { InputFieldType } from '../../../shared/constants/enums/inputFieldTypesEnum'
import { KeyboardKey } from '../../../shared/constants/enums/keyboardKeyEnum'
import {
  OverviewFields,
  OverviewHeaders,
} from '../../../shared/constants/enums/overviewDataGridColumnsEnum'
import { PhoneNumbePrefixes } from '../../../shared/constants/enums/phoneNumberPrefixEnum'
import { DropdownOption } from '../../../shared/models/DropdownOption.model'
import { Patient } from '../../../shared/models/Patient.model'
import { Psychologist } from '../../../shared/models/Psychologist.model'
import { useData } from '../../../store/data/dataProvider'
import { generateProgramOptions } from '../Details/utils/Utils'

interface RegisterProps {}

export const NO_EMAIL_PLACEHOLDER = 'noemail@6th.tech';

const initPatientFormValues: Patient = {
  firstName: '',
  lastName: '',
  age: undefined,
  gender: undefined,
  phoneNumberPrefix: PhoneNumbePrefixes.BG,
  phoneNumber: '',
  email: '',
  operatorId: undefined,
  diagnosis: undefined,
  procedures: undefined,
  program: undefined,
  medication: undefined,
  medicationRecipe: undefined,
  existingRecipes: [],
  removedRecipeIds: [],
  isOnMedication: false,
  isAnonymized: false,
  note: '',
  active: true,
  noEmail: false
}

const autoCompleteValue = 'on'

const Register: React.FC<RegisterProps> = () => {
  const dataProvider = useData()!
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()

  const [patientData, setPatientData] = useState<Patient>({ ...initPatientFormValues })
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState<boolean>(true)
  const [psychologistOptions, setPsychologistOptions] = useState<DropdownOption[]>([])

  const isEditMode = useMemo(() => !!params.id, [params.id])
  const programOptions = useMemo(() => {
    return generateProgramOptions(dataProvider.psychologistPrograms)
  }, [dataProvider.psychologistPrograms])

  useEffect(() => {
    dataProvider.fetchPatientComposeDetails(params.id)
    return () => dataProvider.setPatientData(null)
  }, [])

  useEffect(() => {
    if (!dataProvider.patientData) return

    const {
      createdAt,
      updatedAt,
      publishedAt,
      uuid,
      locale,
      visitedOperator,
      medicationRecipe,
      email,
      ...data
    } = dataProvider.patientData

    setPatientData({
      ...data,
      operatorId: +(data.operatorId as any)?.id,
      isOnMedication: data.medication ? data.medication.length > 0 : false,
      medication: data.medication
        ? medicationDropdownOptions.filter((m) =>
            data.medication ? data.medication.includes(m.value) : false,
          )
        : [],
      existingRecipes: medicationRecipe,
      email: email.includes(NO_EMAIL_PLACEHOLDER) ? '' : email,
      noEmail: email.includes(NO_EMAIL_PLACEHOLDER)
    })
  }, [dataProvider.patientData])

  useEffect(() => {
    const psychologistDropdownOptions: {
      value: string
      text: string
    }[] = dataProvider.psychologistsData.map((psychologist: Psychologist) => ({
      value: `${psychologist.id}`,
      text: `${psychologist?.username}`,
    }))

    setPsychologistOptions(psychologistDropdownOptions)
  }, [dataProvider.psychologistsData])

  useEffect(() => {
    if (!patientData.operatorId) return
    dataProvider.fetchSinglePsychologistPrograms(+patientData.operatorId)
  }, [patientData.operatorId])

  useEffect(() => {
    if (!dataProvider.patientPrograms?.length) return

    const currentPatientProgram = dataProvider.patientPrograms.find(
      (patientProgram) => `${patientProgram.patientId.id}` === `${params.id}`,
    )
    if (!currentPatientProgram) return

    setPatientData((prevData) => ({
      ...prevData,
      procedures: currentPatientProgram.count,
      program: `${currentPatientProgram.programId.id}`,
    }))
  }, [dataProvider.patientPrograms])

  useEffect(() => {
    const {
      isAnonymized,
      isOnMedication,
      isConfirmed,
      email,
      medication,
      medicationRecipe,
      note,
      active,
      removedRecipeIds,
      existingRecipes,
      procedures,
      noEmail,
      ...requiredData
    } = {
      ...patientData,
    }
    let areMedicationFieldsCorrect = !isOnMedication
    if (isOnMedication) {
      areMedicationFieldsCorrect = !!(medication && medication.length > 0)
    }
    const isEveryFieldFilled: boolean = Object.values(requiredData ?? {}).every((value) => !!value)
    const isProceduresFilled = !!(procedures && +procedures > 0)
    const isEmailFilled = email?.length > 0 || noEmail

    setIsSubmitBtnDisabled(
      !(isEveryFieldFilled && areMedicationFieldsCorrect && isProceduresFilled && isEmailFilled),
    )
  }, [patientData])

  const onInputChange = (e: any): void => {
    const inputFieldName: OverviewFields = (e.target as HTMLInputElement).name as OverviewFields
    const inputFieldValue: string | number | boolean | Gender | Diagnosis | PhoneNumbePrefixes =
      e.target.value
    const isAnonymizedCheckbox: boolean = inputFieldName === OverviewFields.IS_ANONYMIZED
    const isOnMedicationCheckbox: boolean = inputFieldName === OverviewFields.IS_ON_MEDICATION
    const isCheckbox =
      inputFieldName === OverviewFields.ACTIVE || isAnonymizedCheckbox || isOnMedicationCheckbox

    const medicationValue = {
      ...(isOnMedicationCheckbox && !e.target.checked
        ? { [OverviewFields.MEDICATION]: [], medicationRecipe: undefined }
        : {}),
    }
    const anonymizedValues = {
      ...(isAnonymizedCheckbox
        ? {
            [OverviewFields.FIRSTNAME]: e.target.checked ? AnonymizedPatientData.NAME : '',
            [OverviewFields.LASTNAME]: e.target.checked ? AnonymizedPatientData.NAME : '',
            [OverviewFields.PHONE_NUMBER]: e.target.checked
              ? AnonymizedPatientData.PHONE_NUMBER
              : '',
          }
        : {}),
    }

    const resetProgramSelection =
      inputFieldName === OverviewFields.OPERATOR && inputFieldValue !== patientData.operatorId

    setPatientData((prevData) => ({
      ...prevData,
      [inputFieldName]: isCheckbox ? e.target.checked : inputFieldValue,
      program: resetProgramSelection ? undefined : prevData.program,
      ...medicationValue,
      ...anonymizedValues,
    }))

    dataProvider.setErrors((prevState: any) => {
      if (prevState[inputFieldName] == undefined) return prevState
      const { [inputFieldName]: updatedField, ...errors } = prevState

      return errors
    })
  }

  const onNoEmailCheckboxChange = (e: any): void => {
    const inputFieldValue: boolean = e.target.checked;

    const emailValue = {
      ...(inputFieldValue ? {
        [OverviewFields.EMAIL]: ''
      }: {})
    }

    setPatientData((prevData) => ({
      ...prevData,
      noEmail: inputFieldValue,
      ...emailValue
    }))
  }

  const onMedicationChange = (selectedOptions: any): void => {
    setPatientData((prevData) => ({
      ...prevData,
      [OverviewFields.MEDICATION]: selectedOptions,
    }))
  }

  const onFileUpload = (files: FileList | null): void => {
    setPatientData((prevData) => ({
      ...prevData,
      medicationRecipe: files,
    }))
  }

  const onRecipeRemove = (recipeId: any): void => {
    setPatientData((prevState) => {
      const removedRecipes = [recipeId]
      if (prevState?.removedRecipeIds) {
        removedRecipes.push(...prevState.removedRecipeIds)
      }

      return {
        ...prevState,
        removedRecipeIds: removedRecipes,
      }
    })
  }

  const onProgramChange = (value: string): void => {
    setPatientData((prevData) => ({
      ...prevData,
      program: value,
    }))
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const inputFieldType: string = (e.target as any).type
    const key: string = e.key
    if (key.includes(KeyboardKey.ARROW)) return

    const inputFieldName: string | undefined = (e.target as any).name

    if (inputFieldType === InputFieldType.NUMBER) {
      if (inputFieldName === OverviewFields.PROCEDURES) {
        const isValidProceduresCount: boolean =
          /^[0-9]\d*$/.test(key) ||
          (key === KeyboardKey.ZERO && (e.target as HTMLInputElement).value === '')
        if (!isValidProceduresCount && key !== KeyboardKey.BACKSPACE && key !== KeyboardKey.TAB) {
          e.preventDefault()
        }
      }
    }

    if (inputFieldType === InputFieldType.TEXT) {
      if (
        inputFieldName === OverviewFields.FIRSTNAME ||
        inputFieldName === OverviewFields.LASTNAME
      ) {
        // Only letters and minus
        const isValidStringRegExp = /^[A-Za-zА-Яа-я-]*$/
        if (!isValidStringRegExp.test(key) && key !== KeyboardKey.BACKSPACE) {
          e.preventDefault()
        }
      } else if (inputFieldName === OverviewFields.PHONE_NUMBER) {
        const isValidPhoneNumber: boolean =
          /^[0-9]\d*$/.test(key) ||
          (key === KeyboardKey.ZERO && (e.target as HTMLInputElement).value === '')
        if (!isValidPhoneNumber && key !== KeyboardKey.BACKSPACE && key !== KeyboardKey.TAB) {
          e.preventDefault()
        }
      } else if (inputFieldName === OverviewFields.EMAIL) {
        // Only letters, dot, and at sign
        const isValidEmailRegExp = /^[A-Za-z0-9._%+-@]*$/
        if (!isValidEmailRegExp.test(key) && key !== KeyboardKey.BACKSPACE) {
          e.preventDefault()
        }
      }
    }

    if (key === KeyboardKey.ENTER && !isSubmitBtnDisabled) {
      handleCreateBtnClick(e)
    }
  }

  const handleCreateBtnClick = (e: any): void => {
    e.preventDefault()

    const { medication, medicationRecipe, ...data } = patientData
    const phoneNumberWithoutLeadingZero: string =
      data.phoneNumber === AnonymizedPatientData.PHONE_NUMBER
        ? AnonymizedPatientData.PHONE_NUMBER
        : data.phoneNumber.replace(/^0+/, '')
    const trimWhiteSpaceInsideRegExp = new RegExp(/\s/g)
    const formattedEmail: string = data.email.replace(trimWhiteSpaceInsideRegExp, '');
    const medicationValues = medication?.map((m) => m.value)

    dataProvider.createPatient(
      {
        ...data,
        phoneNumber: phoneNumberWithoutLeadingZero,
        email: formattedEmail,
        age: +data.age!,
        ...(medication && { medication: medicationValues }),
        ...(medicationRecipe && { medicationRecipe }),
      },
      (success) => {
        if (success) {
          navigate(RoutePaths.PATIENT_OVERVIEW)
        }
      },
    )
  }

  const handleEditBtnClick = (e: any): void => {
    e.preventDefault()

    const { id, isOnMedication, medication, medicationRecipe, existingRecipes, ...data } =
      patientData
    const phoneNumberWithoutLeadingZero: string =
      data.phoneNumber === AnonymizedPatientData.PHONE_NUMBER
        ? AnonymizedPatientData.PHONE_NUMBER
        : data.phoneNumber.replace(/^0+/, '')
    const trimWhiteSpaceInsideRegExp = new RegExp(/\s/g)
    const formattedEmail: string = data.email.replace(trimWhiteSpaceInsideRegExp, '')

    dataProvider.updatePatient(
      +params.id!,
      {
        ...data,
        phoneNumber: phoneNumberWithoutLeadingZero,
        email: formattedEmail,
        age: +data.age!,
        ...(medication && { medication: medication.map((m) => m.value) }),
        ...(medicationRecipe && { medicationRecipe }),
      },
      (success) => {
        if (success) {
          navigate(RoutePaths.PATIENT_OVERVIEW)
        }
      },
    )
  }

  return (
    <>
      <LoadingSpinner isVisible={dataProvider.isLoading} />

      <section className='container--center'>
        <EuiPageHeader
          pageTitle={
            isEditMode
              ? t('register.editPatientDetailsTitle')
              : t('register.createPatientDetailsTitle')
          }
          paddingSize='l'
          style={{ textAlign: 'center' }}
        />

        <EuiForm component='form' className='form'>
          <EuiFormRow label=''>
            <EuiCheckbox
              name={OverviewFields.IS_ANONYMIZED}
              id='is-anonymized-checkbox-id'
              label={t('register.anonymizeMyData')}
              checked={patientData?.isAnonymized}
              onChange={onInputChange}
            />
          </EuiFormRow>

          <EuiFormRow label={OverviewHeaders.FIRSTNAME}>
            <EuiFormControlLayout isInvalid={!!dataProvider.errors?.[OverviewFields.FIRSTNAME]}>
              <EuiFieldText
                name={OverviewFields.FIRSTNAME}
                value={patientData?.firstName}
                isInvalid={!!dataProvider.errors?.[OverviewFields.FIRSTNAME]}
                autoComplete={autoCompleteValue}
                onChange={onInputChange}
                onKeyDown={onKeyDown}
                disabled={patientData.isAnonymized}
              />
            </EuiFormControlLayout>
          </EuiFormRow>

          <EuiFormRow label={OverviewHeaders.LASTNAME}>
            <EuiFormControlLayout isInvalid={!!dataProvider.errors?.[OverviewFields.LASTNAME]}>
              <EuiFieldText
                name={OverviewFields.LASTNAME}
                value={patientData?.lastName}
                isInvalid={!!dataProvider.errors?.[OverviewFields.LASTNAME]}
                autoComplete={autoCompleteValue}
                onChange={onInputChange}
                onKeyDown={onKeyDown}
                disabled={patientData.isAnonymized}
              />
            </EuiFormControlLayout>
          </EuiFormRow>

          <EuiFormRow label={OverviewHeaders.AGE}>
            <EuiFormControlLayout isInvalid={!!dataProvider.errors?.[OverviewFields.AGE]}>
              <EuiFieldNumber
                name={OverviewFields.AGE}
                value={patientData?.age ? +patientData.age : ''}
                isInvalid={!!dataProvider.errors?.[OverviewFields.AGE]}
                autoComplete={autoCompleteValue}
                min={0}
                step={1}
                onChange={onInputChange}
                onKeyDown={onKeyDown}
              />
            </EuiFormControlLayout>
          </EuiFormRow>

          <EuiFormRow label={OverviewHeaders.GENDER}>
            <EuiFormControlLayout isInvalid={!!dataProvider.errors?.[OverviewFields.GENDER]}>
              <EuiSelect
                name={OverviewFields.GENDER}
                value={patientData.gender}
                isInvalid={!!dataProvider.errors?.[OverviewFields.GENDER]}
                autoComplete={autoCompleteValue}
                options={genderDropdownOptions}
                hasNoInitialSelection
                onChange={onInputChange}
              />
              <EuiIcon type='arrowDown' className='dropdownIcon' />
            </EuiFormControlLayout>
          </EuiFormRow>

          <EuiFormRow label={OverviewHeaders.PHONE_NUMBER}>
            <div className='phoneNumberContainer'>
              <EuiFormControlLayout
                isInvalid={!!dataProvider.errors?.[OverviewFields.PHONE_NUMBER_PREFIX]}
                className='phoneNumberPrefixFieldWrapper'
              >
                <EuiSelect
                  name={OverviewFields.PHONE_NUMBER_PREFIX}
                  value={patientData.phoneNumberPrefix}
                  isInvalid={!!dataProvider.errors?.[OverviewFields.PHONE_NUMBER_PREFIX]}
                  autoComplete={autoCompleteValue}
                  options={phoneNumberPrefixDropdownOptions}
                  hasNoInitialSelection
                  onChange={onInputChange}
                  disabled={patientData.isAnonymized}
                />
                <EuiIcon type='arrowDown' className='dropdownIcon' />
              </EuiFormControlLayout>
              <EuiFormControlLayout
                isInvalid={!!dataProvider.errors?.[OverviewFields.PHONE_NUMBER]}
              >
                <EuiFieldText
                  name={OverviewFields.PHONE_NUMBER}
                  value={patientData?.phoneNumber}
                  isInvalid={!!dataProvider.errors?.[OverviewFields.PHONE_NUMBER]}
                  autoComplete={autoCompleteValue}
                  onChange={onInputChange}
                  onKeyDown={onKeyDown}
                  disabled={patientData.isAnonymized}
                />
              </EuiFormControlLayout>
            </div>
          </EuiFormRow>

          <EuiFormRow label={OverviewHeaders.EMAIL}>
            <div>
              <EuiFormControlLayout isInvalid={!!dataProvider.errors?.[OverviewFields.EMAIL]}>
                <EuiFieldText
                  name={OverviewFields.EMAIL}
                  value={patientData?.email}
                  isInvalid={!!dataProvider.errors?.[OverviewFields.EMAIL]}
                  autoComplete={autoCompleteValue}
                  onChange={onInputChange}
                  onKeyDown={onKeyDown}
                  disabled={patientData?.noEmail}
                />
              </EuiFormControlLayout>
              <EuiFormRow label=''>
                <EuiCheckbox
                  id='has-no-email-checkbox-id'
                  name='noEmailCheckbox'
                  label={t('register.noEmail')}
                  checked={patientData?.noEmail}
                  onChange={onNoEmailCheckboxChange}
                />
              </EuiFormRow>
            </div>
          </EuiFormRow>

          <EuiFormRow label={OverviewHeaders.OPERATOR}>
            <EuiFormControlLayout isInvalid={!!dataProvider.errors?.[OverviewFields.OPERATOR]}>
              <EuiSelect
                name={OverviewFields.OPERATOR}
                value={patientData?.operatorId?.toString()}
                isInvalid={!!dataProvider.errors?.[OverviewFields.OPERATOR]}
                autoComplete={autoCompleteValue}
                options={psychologistOptions}
                hasNoInitialSelection
                onChange={onInputChange}
              />
              <EuiIcon type='arrowDown' className='dropdownIcon' />
            </EuiFormControlLayout>
          </EuiFormRow>

          <EuiFormRow label={OverviewHeaders.DIAGNOSIS}>
            <EuiFormControlLayout isInvalid={!!dataProvider.errors?.[OverviewFields.DIAGNOSIS]}>
              <EuiSelect
                name={OverviewFields.DIAGNOSIS}
                value={patientData.diagnosis}
                isInvalid={!!dataProvider.errors?.[OverviewFields.DIAGNOSIS]}
                autoComplete={autoCompleteValue}
                options={diagnosisDropdownOptions}
                hasNoInitialSelection
                onChange={onInputChange}
              />
              <EuiIcon type='arrowDown' className='dropdownIcon' />
            </EuiFormControlLayout>
          </EuiFormRow>

          <MedicationInputs
            isOnMedication={patientData.isOnMedication}
            medication={patientData.medication}
            onInputChange={onInputChange}
            onMedicationChange={onMedicationChange}
            onFileUpload={onFileUpload}
            errors={dataProvider.errors}
          />

          {/* TODO: move to medication inputs */}
          {isEditMode &&
          patientData?.existingRecipes &&
          patientData.existingRecipes.length !== patientData?.removedRecipeIds?.length ? (
            <EuiFormRow label={t('register.uploadRecipes')}>
              <>
                {patientData.existingRecipes.map((recipe: any) => {
                  if (!patientData.removedRecipeIds?.includes(recipe.id)) {
                    return (
                      <EuiBadge
                        key={recipe.id}
                        style={{ margin: '4px' }}
                        color='hollow'
                        iconType='cross'
                        iconSide='right'
                        iconOnClick={() => onRecipeRemove(recipe.id)}
                        iconOnClickAriaLabel='Example of onClick event for icon within the button'
                      >
                        {recipe.name}
                      </EuiBadge>
                    )
                  }
                })}
              </>
            </EuiFormRow>
          ) : (
            <></>
          )}

          <EuiFormRow label={t('register.notes')}>
            <EuiTextArea
              name={OverviewFields.NOTE}
              value={patientData.note}
              onChange={onInputChange}
            />
          </EuiFormRow>

          <EuiFormRow label={t('register.program')}>
            <EuiRadioGroup
              name='program'
              idSelected={patientData.program}
              options={programOptions}
              legend={{
                children:
                  programOptions?.length === 0 ? (
                    <span>{t('register.emptyProgramsPlaceholder')}</span>
                  ) : (
                    <></>
                  ),
              }}
              onChange={onProgramChange}
            />
          </EuiFormRow>

          <EuiFormRow label={t('register.procedures')}>
            <EuiFormControlLayout>
              <EuiFieldNumber
                name={OverviewFields.PROCEDURES}
                value={patientData?.procedures ? +patientData.procedures : ''}
                isInvalid={!!dataProvider.errors?.[OverviewFields.PROCEDURES]}
                autoComplete={autoCompleteValue}
                min={1}
                step={1}
                onChange={onInputChange}
                onKeyDown={onKeyDown}
              />
            </EuiFormControlLayout>
          </EuiFormRow>

          {isEditMode && (
            <EuiFormRow label=''>
              <EuiCheckbox
                id='is-active-checkbox-id'
                name={OverviewFields.ACTIVE}
                label={t('register.active')}
                checked={patientData?.active}
                onChange={onInputChange}
              />
            </EuiFormRow>
          )}

          <EuiButton
            type='submit'
            isDisabled={isSubmitBtnDisabled || dataProvider.isLoading}
            iconType={isEditMode ? 'save' : 'plus'}
            onClick={isEditMode ? handleEditBtnClick : handleCreateBtnClick}
            fullWidth
            fill
          >
            {t('btns.create')}
          </EuiButton>
        </EuiForm>
      </section>
    </>
  )
}

export default Register
