export enum OverviewFields {
  INDEX = 'index',
  ID = 'id',
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  AGE = 'age',
  GENDER = 'gender',
  PHONE_NUMBER_PREFIX = 'phoneNumberPrefix',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  UUID = 'uuid',
  OPERATOR = 'operatorId',
  DIAGNOSIS = 'diagnosis',
  IS_ON_MEDICATION = 'isOnMedication',
  MEDICATION = 'medication',
  MEDICATION_RECIPE = 'medicationRecipe',
  NOTE = 'note',
  IS_ANONYMIZED = 'isAnonymized',
  VISITED_OPERATOR = 'visitedOperator',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PROGRAM = 'patient_programs',
  PROCEDURES = 'procedures',
  VISITS = 'visits',
  ACTIVE = 'active',
  IS_CONFIRMED = 'isConfirmed',
  RESULTS_LINK = 'resultsLink',
  CHART_RESULTS_LINK = 'chartResultsLink',
}

export enum OverviewHeaders {
  INDEX = 'Номер',
  ID = 'ID',
  FIRSTNAME = 'Име*',
  LASTNAME = 'Фамилия*',
  AGE = 'Възраст*',
  GENDER = 'Пол*',
  PHONE_NUMBER_PREFIX = 'Тел. префикс*',
  PHONE_NUMBER = 'Тел. номер*',
  EMAIL = 'Електронна поща',
  UUID = '6-цифрен идент. номер',
  OPERATOR = 'Оператор*',
  DIAGNOSIS = 'Диагноза*',
  IS_ON_MEDICATION = 'Взима лекарства',
  MEDICATION = 'Лекарства',
  MEDICATION_RECIPE = 'Рецепти',
  NOTE = 'Бележки',
  IS_ANONYMIZED = 'Анонимен',
  VISITED_OPERATOR = 'Посетил оператор',
  CREATED_AT = 'Създаден на',
  UPDATED_AT = 'Обновен на',
  PROGRAM = 'Програма*',
  PROCEDURES = 'Процедури*',
  VISITS = 'Сесии',
  ACTIVE = 'Активен',
  IS_CONFIRMED = 'Потвърден',
  // RESULTS_LINK = 'Резултати',
  // ID = 'ID',
  // FIRSTNAME = 'First Name',
  // LASTNAME = 'Last Name',
  // AGE = 'Age',
  // GENDER = 'Sex',
  // PHONE_NUMBER_PREFIX = 'Prefix',
  // PHONE_NUMBER = 'Phone Number',
  // EMAIL = 'E-mail',
  // UUID = '6 digit unique identifier',
  // OPERATOR = 'Operator',
  // DIAGNOSIS = 'Diagnosis',
  // IS_ON_MEDICATION = 'Is On Medication',
  // MEDICATION = 'Medication',
  // MEDICATION_RECIPE = 'Medication Recipe',
  // NOTE = 'Note',
  // IS_ANONYMIZED = 'Anonymized',
  // VISITED_OPERATOR = 'Visited operator',
  // CREATED_AT = 'Created At',
  // UPDATED_AT = 'Updated At',
  // PROGRAM = 'Program',
  // PROCEDURES = 'Procedures',
  // VISITS = 'Visits',
  // ACTIVE = 'Active',
  // IS_CONFIRMED = 'Confirmed',
}

export enum OverviewFilters {
  TOGGLE_INACTIVE_PATIENTS = 'Включи неактивните пациенти',
  FILTER_BY_OPERATOR = 'Избери оператор'
}
