import { Toast } from '@elastic/eui/src/components/toast/global_toast_list'

const patientRegisterToasts: { [key: string]: Toast } = {
  createPatientSuccess: {
    id: 'success-patient-create',
    title: 'Успешен запис!',
    color: 'success',
    iconType: 'check',
    text: 'Профилът на пациента беше успешно създаден!',
  },
  createPatientFailed: {
    id: 'fail-patient-create',
    title: 'Нещо се обърка.',
    color: 'danger',
    iconType: 'alert',
    text: 'Извинете ни, ще опитаме това да не се повтавя.',
  },
}

export default patientRegisterToasts
