import { Navigate, Route, Routes } from 'react-router-dom'

import Home from '../../containers/Home/Home'
import Login from '../../containers/Login/Login'
import Overview from '../../containers/Patient/Overview/Overview'
import Register from '../../containers/Patient/Register/Register'
import { RouteKeys, RoutePaths } from '../../shared/constants/enums/RoutePathsEnum'
import { RouteModel } from '../../shared/models/Route.model'
import { DataProvider } from '../../store/data/dataProvider'
import ToastNotificator from '../ToastNotificator/ToastNotificator'
import ProtectedRoute from './ProtectedRoute'

export const RenderRoutes = ({ isAuth, routes }: { isAuth: boolean; routes: RouteModel[] }) => {
  return (
    <Routes>
      {routes.map((route: RouteModel) => {
        const component = route.isProtected ? (
          <ProtectedRoute isAuth={isAuth}>{route.element}</ProtectedRoute>
        ) : (
          route.element
        )

        return <Route key={route.key} path={route.path} element={component} />
      })}
      <Route path={RoutePaths.ANY} element={<Navigate to={RoutePaths.ROOT} replace />} />
    </Routes>
  )
}

const ROUTES: RouteModel[] = [
  {
    path: RoutePaths.ROOT,
    key: RouteKeys.ROOT,
    element: <Login />,
    isProtected: false,
  },
  {
    path: RoutePaths.HOME,
    key: RouteKeys.HOME,
    element: <Home />,
    isProtected: true,
  },
  {
    path: RoutePaths.PATIENT_REGISTER,
    key: RouteKeys.PATIENT_REGISTER,
    element: (
      <DataProvider>
        <Register />
        <ToastNotificator />
      </DataProvider>
    ),
    isProtected: true,
  },
  {
    path: RoutePaths.PATIENT_EDIT,
    key: RouteKeys.PATIENT_EDIT,
    element: (
      <DataProvider>
        <Register />
        <ToastNotificator />
      </DataProvider>
    ),
    isProtected: true,
  },
  {
    path: RoutePaths.PATIENT_OVERVIEW,
    key: RouteKeys.PATIENT_OVERVIEW,
    element: (
      <DataProvider>
        <Overview />
        <ToastNotificator />
      </DataProvider>
    ),
    isProtected: true,
  },
]

export default ROUTES
