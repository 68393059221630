export enum RoutePaths {
  ROOT = '/',
  HOME = '/home',
  PATIENT_OVERVIEW = '/patient',
  PATIENT_REGISTER = '/patient/register',
  PATIENT_EDIT = '/patient/:id',
  ANY = '*',
}

export enum RouteKeys {
  ROOT = 'ROOT',
  HOME = 'HOME',
  PATIENT_OVERVIEW = 'PATIENT_OVERVIEW',
  PATIENT_REGISTER = 'PATIENT_REGISTER',
  PATIENT_EDIT = 'PATIENT_EDIT',
}
